.apply-button{
    position: absolute;
    z-index: 2;
    right: 0px;
    width: 170px;
    bottom: 101%;
    button{
    font-size: 0.8125rem !important;
    }
}

.OrgBasicInfo{
    .apply-button{
        position: absolute;
        z-index: 2;
        width: 160px;
        bottom: 95% !important;
    }
}

.general-margin {
    margin-right: 0px !important;
}

.culture{
    text-transform: uppercase;
}
.dropDownLabel label{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
}
.orgLogo label{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    border-radius: 20px !important;
}

.orgBasicInfoLabel {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
}
.orgLogo > span > div > span {
    padding: 22px 0 18px 21px !important;
}
.basicInfoCover {
    padding-left: 20px;
    button {
        font-size: 0.75rem;
    }
}
.basicInfoDeleteIcon {
    position: absolute;
    right: 18px;
    bottom: 18px;
    height: 15px;
    width: 15px;
}
.basicInfoRow {
    margin-top: 37px;
}
.mt-20 {
    margin-top: 20px !important;
}
.uploadBasicInfo {
    border-style: solid !important;
}
.uploadBasicInfo img:first-child {
    max-width: 25vw;
    width: 100%;
    max-height: 25vh;
    margin-top: 5px;
    margin-bottom: 10px;
}

.ant-upload.ant-upload-btn:focus-visible {
    border: 2px solid black !important;
    border-radius: 5px;
}
.subscriptionTab {
    position: inherit !important;
    .roleImg {
        width: 20px;
        height: 20.29px;
    }
}
.subscriptionTab .commonRowDesc {
    color: #4d4d4d;
    padding-right: 4.42%;
}
.basicRowGutter {
    margin-left: 0px !important;
    margin-right: 0px !important;
    input {
        font-size: 0.875rem;
    }
}

.regionDropdown .ant-select-item-option-content {
    font-size: 0.875rem;
}

.apply-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-select.dropDownBorder.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    border-radius: 10px !important;
    border: 2px solid transparent !important;
  }

.ant-select.dropDownBorder.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    border: 2px solid black !important;
  }