.ant-col.ant-form-item-label{
    font-family: IBM Plex Sans;
    font-weight: 500;
}
.profile{
    padding-top: 58px;
    padding-left:10px;
}
.setting-form{
    padding-top: 58px;
    padding-left:0px;
}

.profileFormControl > label {
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
}

.profilePic + .ant-image-mask {
    border-radius: 50%;
    overflow: hidden;
}

.notificationSettingsLabel {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px;
    color: #1C1C1C;
}

.switchCard {
    height: 54px !important;
    background: #FFFFFF !important;
    border: 1px solid #CBCBCB !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    align-items: center;
}

.switchCard > .ant-card-body {
    padding: 0;
    padding-left: 12px !important;
}

.switchCardLabel {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem !important;
    line-height: 40px;
    margin-left: 12px;
    color: #1C1C1C;
}

.notiSwitch {
    border: 1px solid #CBCBCB !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    background-color: #fff !important;
    width: 21px !important;
    height: 14px !important;
    min-width: 21px !important;
}
.notiSwitch .ant-switch-handle {
    width: 10px !important;
    height: 10px !important;
    top: 1px;
}
.ant-switch-checked{
    .ant-switch-handle{
        left: calc(100% - 10px - 2px) !important;
    }
}
.notiSwitch .ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1c1c1c !important;
    border-radius: 50px;
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    transition: all 0.2s ease-in-out;
    content: "";
}

.notificationSettingsSaveButton {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4D4D4D;
}
.profilepicture {
    width: 240px !important;
    height: 240px !important;
    border-radius: 50% !important;
    box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
     0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
     0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497), 
     0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045), 
     0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
     0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031) !important;
}
.accImg {
    display: flex;
    justify-content: center;
}
.accHed {
    align-items: center;
}
.accHed-margin{
    margin-left: 0 !important;
    margin-right: 16.5 !important;
    justify-content: space-between;
}

.general-padding {
    padding-right: 0px !important;
    padding-left: 33px !important;
}


.accSettHed{
    display: flex;
    align-items: center;
    margin-left: 1vw;
}
.editBtn{
    padding: 0 2em 0 2em;
}
.accountHeader{
    display: flex;
    align-items: center; 
}
.accSettHedLeft {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4375rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1C1C;
}
.accSettHedRight {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media only screen and (max-width: 900px) {
        padding-right: 13px !important;
    }
}
.hedMinHeight .ant-form-item-control-input {
    min-height: auto !important;
}
.assignedLocationList{
    max-height: 35vh;
    position: relative;
}
.checkboxDisabled{
    cursor: url(../../../../../assets/images/devices.svg),auto;
    div{
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.55);
    }
    .assignedLocation{
        border: 1px solid #ededed;
    }
    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after{
        background-color: rgba(0, 0, 0, 0.25);
    }
}
.w-100 {
    width: 100%;
}
.ml-23 {
    margin-left: 23px;
}
.mt-8 {
    margin-top: 8px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-25 {
    margin-top: 25px;
}
.mt-29 {
    margin-top: 29px;
}
.mt-37 {
    margin-top: 37px !important;
}
.mt-47 {
    margin-top: 47px;
}
.mt-48 {
    margin-top: 48px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-11 {
    margin-bottom: 11px;
}
.mb-30 {
    margin-bottom: 30px;
}
.pr-41 {
    padding-right: 41px;
}
.pt-30 {
    padding-top: 30px;
}
.accountResetBtn img {
    width: 20.29px;
    height: 15px;
}
.accountResetBtn span {
    margin-right: 0px;
    padding-right: 0px;
}
.accountResetBtn {
    justify-content:flex-end;
}
.headRightBlock {
    padding-right: 11.21px !important;
}
.headSavedBlock {
 
    @media only screen and (max-width: 900px) {
        padding-left: 0px !important;
    }
}
@media screen and (min-width:992px) {
    .accountPage .errorMsg{
        padding-bottom: 10px;
    }   
}
@media screen and (max-width:991px) {
    .mt-48{
        margin-top: 0 !important;   
    }
    .accImg{
        padding-bottom: 20px;
    }
    .accountForm{
        padding: 30px 0 !important;
    }
    .userForm{
        margin: 0 !important;
    }
    .pr-41{
        padding-right: 0 !important;
    }
    .accHed{
        padding-bottom: 20px;
    }
}
.accountLabel {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
}
.hedMinHeight button {
    font-size: 0.75rem !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.accountCol input {
    font-size: 0.875rem;
}
.notiSwitch {
    width: 28px !important;
}
.notiSwitch .ant-switch-handle::before {
    background: #737373 !important;
}
.switchCard .ant-switch-checked {
    background: #13A922 !important;
}
.switchCard .ant-switch-checked .ant-switch-handle::before {
    background: #fff !important;
}
.disableLocBorderColor{
    border: 1px solid #ededed;
    // cursor: url(/static/media/disabled.19256ef5.svg), auto !important;
    cursor: url(../../../../../assets/images/devices.svg),auto;
    background: #f5f5f5 !important;
}
.checkBoxinput-P0{
    padding-right: 0px !important;
}

.noti-Padding{
    padding-left: 20.5px !important;
}
.notificationFormItemStyle{
    margin-left: auto;
}
.notifydivStyle{
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.loc-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}